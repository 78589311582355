.checkbox-selector-container .mat-checkbox-label {
  line-height: 14px !important;
}

.logout-dialog-background {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 0);
}

@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
  .logout-dialog-background {
    backdrop-filter: blur(10px);
    color: rgba(0, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
  }
}