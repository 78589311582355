body {
  margin: 0;
}

.mat-horizontal-content-container {
  padding: 0 24px !important;
}

.mat-horizontal-stepper-header-container {
  height: 72px;
}

#ie-not-supported-obj {
  color: rgb(45, 0, 87);
  font-family: 'Roboto', sans-serif;
  margin: 100px auto;
  width: 400px;
}

.ie-logo-background {
  background-color: rgb(45, 0, 87);
  border-radius: 25px;
  padding: 25px;
}

.mat-menu-panel.notifications-menu {
  max-width: none;
}

.mat-dialog-container {
  max-height: 100vh !important;
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
